import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-66c8a17c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "width": "100%",
    "height": "60px",
    "background": "linear-gradient(to right, rgb(211, 214, 162), rgb(120, 229, 248))",
    "margin": "5px 5px 5px 5px",
    "border-radius": "8px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_tag = _resolveComponent("van-tag");
  const _component_van_row = _resolveComponent("van-row");
  const _component_van_button = _resolveComponent("van-button");
  return _openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = (...args) => $options.topClick && $options.topClick(...args))
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: 18
    }, {
      default: _withCtx(() => [_createVNode(_component_van_row, {
        style: {
          "font-size": "12px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          span: 6,
          style: {
            "font-size": "12px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode("姓名：")]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: 18,
          style: {
            "font-size": "12px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($props.names) + " ", 1), $props.area ? (_openBlock(), _createBlock(_component_van_tag, {
            key: 0,
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString($props.area), 1)]),
            _: 1
          })) : (_openBlock(), _createBlock(_component_van_tag, {
            key: 1,
            type: "danger"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString('未设置'))]),
            _: 1
          }))]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: 6,
          style: {
            "font-size": "12px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode("证件号：")]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: 18,
          style: {
            "font-size": "12px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($props.idCard), 1), $props.badge ? (_openBlock(), _createBlock(_component_van_tag, {
            key: 0,
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString($props.badge), 1)]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: 6,
          style: {
            "font-size": "12px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode("创建时间：")]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: 18,
          style: {
            "font-size": "12px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($props.createTime), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_van_col, {
      span: 6,
      style: {
        "padding": "5px 5px 5px 5px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_van_button, {
        onClick: _withModifiers($options.btnClick, ["stop"]),
        size: "small",
        type: "success",
        style: {
          "font-size": "12px",
          "height": "100%"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.optxt), 1)]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  })])]);
}