import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-82bc6c36"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "body"
};
const _hoisted_2 = {
  class: "border"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_empty = _resolveComponent("van-empty");
  const _component_router_link = _resolveComponent("router-link");
  const _component_ContactCard = _resolveComponent("ContactCard");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_cell_group, {
    inset: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_field, {
      modelValue: $data.query.names,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.query.names = $event),
      label: "姓名"
    }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
      modelValue: $data.query.idCard,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.query.idCard = $event),
      label: "身份证号"
    }, null, 8, ["modelValue"]), _createVNode(_component_van_button, {
      type: "success",
      onClick: _cache[2] || (_cache[2] = $event => $options.onSearch()),
      size: "small",
      style: {
        "float": "right",
        "width": "96%"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_van_icon, {
        name: "search"
      }), _createTextVNode("查询")]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [$data.myinfos.length == 0 ? (_openBlock(), _createBlock(_component_van_empty, {
    key: 0,
    description: "空空如也"
  })) : _createCommentVNode("", true), _createVNode(_component_router_link, {
    to: "/old/info?type=add"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_button, {
      type: "success",
      style: {
        "width": "96%",
        "margin": "5px 5px 5px 5px"
      }
    }, {
      default: _withCtx(() => [_createTextVNode("添加")]),
      _: 1
    })]),
    _: 1
  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.myinfos, item => {
    return _openBlock(), _createBlock(_component_ContactCard, {
      names: item.names,
      key: item.id,
      area: item.area,
      idCard: item.idCard,
      createTime: item.createTime,
      badge: item.remark,
      onBtnClick: $event => $options.btnClick(item),
      onTopClick: $event => $options.topClick(item),
      optxt: '开始填写'
    }, null, 8, ["names", "area", "idCard", "createTime", "badge", "onBtnClick", "onTopClick"]);
  }), 128))])]);
}